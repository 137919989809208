<template>
  <v-card>
    <v-row>
      <v-col lg="12" cols="md" class="pb-2">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" cols="md" class="pb-2">
        <b-badge>{{ request.timeRequestedTimeMillis }}</b-badge>
      </v-col>

      <v-col lg="6" cols="md" class="pb-2">
        <b-badge>{{ request.status }}</b-badge>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" cols="md" class="pb-2">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["request"],
  methods: {
    toggleItem(event, itemId) {
      this.$emit("toggle-item", event, itemId);
    },
  },
};
</script>
