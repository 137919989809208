<template>
  <div>
    <v-row>
      <v-col lg="3" cols="sm" class="pb-2">
        <router-link to="requests?requestType=pending">
          <v-card>
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="cyan fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-4 cyan--text">
                <h5 class="text-truncate text-uppercase">
                  Pending Requests
                </h5>
                <h1>{{ countPendingRequests }}</h1>
              </div>
            </v-row>
          </v-card>
        </router-link>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <router-link to="requests">
          <v-card>
            <v-row class="no-gutters">
              <div class="col-auto">
                <div class="primary fill-height">&nbsp;</div>
              </div>
              <div class="col pa-3 py-4 primary--text">
                <h5 class="text-truncate text-uppercase">Total Requests</h5>
                <h1>
                  {{
                    getCompanyReports.requests != undefined
                      ? getCompanyReports.requests
                      : "0.0"
                  }}
                </h1>
              </div>
            </v-row>
          </v-card>
        </router-link>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="primary fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Total Impressions</h5>
              <h1>
                {{
                  getCompanyReports.impressions != undefined
                    ? getCompanyReports.impressions
                    : "0.0"
                }}
              </h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2">
        <v-card>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="success fill-height">&nbsp;</div>
            </div>
            <div class="col pa-3 py-4 success--text">
              <h5 class="text-truncate text-uppercase">Wallet Balance</h5>
              <h1>
                UGX
                {{
                  getFreelancerCompany.walletBalance !== undefined
                    ? getFreelancerCompany.walletBalance
                    : ""
                }}
              </h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="12" cols="md" class="pb-2">
        <v-card-title class="primary--text">
          <v-row>
            <v-col lg="11" cols="md pr-md-1 sm-6" class="pb-2">
              <h3 class="font-weight-light mb-2">
                Last Requests
              </h3>
            </v-col>

            <v-col lg="1" class="md pr-md-1 fill-width">
              <a href="/#/requests">
                <template>
                  <v-icon color="primary">mdi-arrow-right-circle</v-icon>
                </template>
              </a>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card class="mx-auto">
          <v-data-table
            :headers="headers"
            :items="getCompanyRequests"
            item-key="id"
            dark
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:item="{ item }">
              <tr @click="loadUserProfile(item.id)">
                <td>
                  {{
                    item.pickupLocationLabel !== undefined
                      ? item.pickupLocationLabel
                      : getFreelancerCompany.locationLabel
                  }}
                </td>
                <td>
                  {{
                    item.destinationLocationLabel !== undefined
                      ? item.destinationLocationLabel
                      : item.destination
                  }}
                </td>
                <td>{{ new Date(item.timeRequestedTimeMillis) | exactDay }}</td>
                <td>{{ item.status }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import HomeRequestCard from "@/components/requests/HomeRequestCard.vue";
import ActiveCompanyLoader from "@/components/loaders/ActiveCompanyLoader";
import CompanyListLoader from "@/components/loaders/CompanyListLoader";
export default {
  template: "#home",

  components: {
    HomeRequestCard,
    ActiveCompanyLoader,
    CompanyListLoader,
  },

  data: () => ({
    sparklineData: [423, 446, 675, 510, 590, 610, 423],
    headers: [
      {
        text: "Departure",
        align: "left",
        sortable: false,
        value: "departure",
      },
      { text: "Destination", value: "destination" },
      { text: "Time Requested", value: "timeRequestedTimeMillis" },
      { text: "Status", value: "status" },
    ],
    sortBy: "timeRequestedTimeMillis",
    sortDesc: true,
  }),

  beforeCreate() {
    this.$store.dispatch("fetchCompanyReports");
    this.$store.dispatch("fetchMyCompany");
    if (this.$route.query.requestType === "pending") {
      this.$store.dispatch(
        "fetchMyCompanyRequests",
        this.$route.query.requestType
      );
    } else {
      this.$store.dispatch("fetchMyCompanyRequests", "all");
    }
  },

  computed: {
    ...mapState(["companyRequests", "freelancerCompany", "companyReports"]),
    ...mapGetters([
      "getCompanyRequests",
      "getFreelancerCompany",
      "messageAlert",
      "getActiveCompany",
      "getCompanyReports",
    ]),

    countPendingRequests() {
      const context = this;
      if (context.getCompanyRequests.length > 0) {
        let pendingRequests = 0;
        for (const index in context.getCompanyRequests) {
          if (context.getCompanyRequests[index].status === "PENDING") {
            pendingRequests++;
          }
        }
        return pendingRequests;
      } else {
        return 0;
      }
    },
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyCompanyRequests");
        this.$store.dispatch("fetchMyCompany");
        this.$store.dispatch("fetchCompanyReports");
      }
    },

    getActiveCompany(val) {
      this.$store.dispatch("fetchMyCompanyRequests");
      this.$store.dispatch("fetchMyCompany");
      this.$store.dispatch("fetchCompanyReports");
      this.$forceUpdate();
    },
  },

  methods: {
    loadUserProfile(requestID) {
      this.$router.push({
        path: "/requestDetails",
        query: { requestID: requestID },
      });
    },
  },

  filters: {
    exactDay(date) {
      return dayjs(date).format("h:mm a, MMMM DD YYYY");
    },
  },
};
</script>
